import './App.css';
import BuyCoin from './components/buy-coin';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Footer from './components/footer/footer';
import HomePage from './pages/homepage/homepage';
import RoadMap from './pages/road-map/road-map';
import PrivacyPolicy from './pages/privacy/privacy';
import TermsOfUse from './pages/terms/terms';
import TopBar from './components/topbar/topbar';
import AboutUs from './pages/about/about';
import ContactUs from './pages/contact/contact';
import MarketPlace from './pages/marketplace/marketplace';

function App() {

  return (
    <BrowserRouter>
      <div className="page-wrapper" >
        <TopBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/road-map" element={<RoadMap />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/marketplace" element={<MarketPlace />} />
          <Route path="/buy-coin" element={<BuyCoin />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
