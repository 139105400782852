import React, { Fragment, useState } from 'react';
import { Pagination } from "react-bootstrap";

export function PaginationComponent({ currentPage, itemsPerPage, totalItems, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const maxVisiblePages = 5; // Change this number to adjust the number of visible pages

    const getVisiblePageNumbers = () => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const visiblePages = [];

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                visiblePages.push(i);
            }
        } else {
            const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

            if (startPage > 1) {
                visiblePages.push(1);
                if (startPage > 2) {
                    visiblePages.push('...');
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                visiblePages.push(i);
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    visiblePages.push('...');
                }
                visiblePages.push(totalPages);
            }
        }

        return visiblePages;
    };

    return (
        <Pagination>
            <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {getVisiblePageNumbers().map((number, index) => (
                <Fragment key={index}>
                    {number === '...' ? (
                        <Pagination.Ellipsis disabled />
                    ) : (
                        <Pagination.Item
                            active={number === currentPage}
                            onClick={() => paginate(number)}
                        >
                            {number}
                        </Pagination.Item>
                    )}
                </Fragment>
            ))}
            <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            />
            <Pagination.Last
                onClick={() => paginate(Math.ceil(totalItems / itemsPerPage))}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            />
        </Pagination>
    );

}