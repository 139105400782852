import React, { useRef, useState, useEffect, Fragment } from 'react';
import { getPlots } from '../services/service';

export function PlanetPlots2D({ elements }) {
    const [polygons, setPolygons] = useState([]);
    const [planetNum, setPlanetNum] = useState(0);

    useEffect(() => {
        if (elements && elements.length > 0) {
            let planetNum = parseInt(elements[0].planetNum);
            getPlots({ planetNum: planetNum }).then((polygons_data) => {
                let plots = [];
                polygons_data.data.plots.forEach((plot) => {
                    plots.push(plot.verticies);
                });
                setPolygons(plots);
                setPlanetNum(planetNum);
            });
        }
    }, [elements]);

    function ImageWithPolygons({ polygons, planetNum, children }) {
        const canvasRef = useRef(null);
        const [polyPath, setPolyPath] = useState([]);
        const [selectedPlot, setSelectedPlot] = useState(null);

        const handleClick = (e) => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            const rect = ctx.canvas.getBoundingClientRect();
            const x = ((e.clientX - rect.left) / rect.width) * canvas.width;
            const y = ((e.clientY - rect.top) / rect.height) * canvas.height;
            polyPath.forEach((path, i) => {
                if (ctx.isPointInPath(path, x, y)) {
                    if (selectedPlot === i) setSelectedPlot(null); else setSelectedPlot(i);
                }
            });
        };

        function drawPolygons(plots) {
            if (!canvasRef?.current) return;
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            let polyPath = [];
            const rx = 1 / 2;
            const ry = 1 / 2;
            plots.forEach((polygon, i) => {
                let path = new Path2D();
                path.moveTo(polygon[0].x * rx, polygon[0].y * ry);
                for (let i = 1; i < polygon.length; i++) {
                    path.lineTo(polygon[i].x * rx, polygon[i].y * ry);
                }
                path.closePath();
                if (i === selectedPlot) {
                    ctx.fillStyle = 'cyan';
                    ctx.fill(path);
                } else {
                    ctx.strokeStyle = 'cyan';
                    ctx.lineWidth = 1;
                    ctx.stroke(path);
                }
                polyPath.push(path);
            });
            setPolyPath(polyPath);
        }

        useEffect(() => {
            if (!canvasRef?.current) return;
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the image on the canvas
            const img = new Image();
            let planetNumStr = planetNum.toString().padStart(4, '0');
            img.src = `${process.env.REACT_APP_API_URL}/images/planets_maps/planet-map-${planetNumStr}.png?width=1024`;
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            img.onload = () => drawPolygons(polygons);

        }, [polygons, planetNum, selectedPlot]);

        return <canvas ref={canvasRef} onClick={handleClick}>
            {children}
        </canvas>;
    }

    return (
        <Fragment>
            <ImageWithPolygons polygons={polygons} planetNum={planetNum}>
            </ImageWithPolygons>
        </Fragment>
    );
}
