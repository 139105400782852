import { Fragment, useState } from "react";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
export default function RoadMap() {
    const items = [
        {
            time: "Q2 - 2022",
            text: (<ul>
                <li>Definition and vision.</li>
                <li>Market Research.</li>
                <li>Develop a business plan.</li>
                <li>Identify the necessary resources.</li>
            </ul>)
        },
        {
            time: "Q1 - 2023",
            text: (<ul>
                <li>Developing the models.</li>
                <li>Create UI/UX.</li>
                <li>Plan the cryptocurrency and NFT systems.</li>
            </ul>)
        },
        {
            time: "Q2 - 2023",
            text: (<ul>
                <li>Build the infrastructure and technical systems.</li>
                <li>Develop contracts.</li>
                <li>Develop the social features, such as chat and events.</li>
            </ul>)
        },
        {
            time: "Q3 - 2023",
            text: (<ul>
                <li>Conduct thorough testing.</li>
                <li>Conduct beta testing.</li>
                <li>Continue refining.</li>
            </ul>)
        },
    ]
    return (
        <Fragment>
            <Timeline lineColor={'#ddd'}>
                {
                    items.map((item, index) => (
                        <TimelineItem
                            key="001"

                            dateText={item.time}
                            dateStyle={{ background: '#2BEDE6' }}
                            dateInnerStyle={{ background: '#000', color: '#AAFFFC' }}
                            bodyContainerStyle={{
                                color: '#AAFFFC',
                                background: '#000',
                                padding: '20px',
                            }}
                        >
                            {item.text}
                        </TimelineItem>
                    ))
                }
            </Timeline>
        </Fragment >
    )
}