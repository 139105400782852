import { Fragment, useEffect, useState } from "react";
import { Canvas, useFrame } from '@react-three/fiber'
import './marketplace.css';
import { Galaxy3JSOptimized } from "../../components/galaxy/galaxy3js_optimized";
import { OrbitalColored2 } from "../../components/orbit/orbital_colored2";
import { getElements, searchElements } from "../../components/services/service";
import { Image } from "../../components/image";
import { PaginationComponent } from "../../components/pagination";
import { PlanetPlots2D } from "../../components/plots/planetplots2d";

export default function MarketPlace() {
    const [elements, setElements] = useState([]);
    const [elementsCount, setElementsCount] = useState(0);
    const [searchParams, setSearchParams] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [type, setType] = useState('star');

    useEffect(() => {
        searchCurrentElements();
    }, [currentPage, type]);

    const paginateTo = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const searchCurrentElements = () => {
        searchElements({ searchParams, type, currentPage, itemsPerPage })
            .then(response => {
                if (response.data) {
                    setElementsCount(response.data?.count);
                    setElements(response.data?.elements);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const updateSearchParams = (val) => {
        setSearchParams(val);
        setCurrentPage(1);
    }

    const submitForm = (e) => {
        e.preventDefault();
        searchCurrentElements();
    }

    return (
        <Fragment>
            <div className="d-flex container col-md-12 pt-5">
                <div className="container d-flex">
                    <div className="col-4">
                        <form onSubmit={submitForm}>
                            <div className="">
                                <h3>Filter</h3>
                            </div>

                            <div className="form-group">
                                <label >Price</label>
                                <input type="range" className="form-control-range" id="" />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-8">
                        <ul className="nav nav-tabs nav-fill nav-tabs-alt">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#tab-alt-1" onClick={e => {
                                    setType('star');
                                    setCurrentPage(1);
                                }}>Solar Systems</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab-alt-2" onClick={e => {
                                    setType('planet');
                                    setCurrentPage(1);
                                }}>Planets</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab-alt-3" onClick={e => {
                                    setType('plot');
                                    setCurrentPage(1);
                                }} >Plots</a>
                            </li>
                        </ul>
                        <div className="tab-content ">
                            <div className="tab-pane active" id="tab-alt-1">
                                <Canvas shadows style={{ background: "black", height: 500, zIndex: -1 }} >
                                    <Galaxy3JSOptimized elements={elements} />
                                </Canvas>
                            </div>
                            <div className="tab-pane" id="tab-alt-2">
                                <Canvas shadows style={{ background: "black", height: 500, zIndex: -1 }} >
                                    <OrbitalColored2 />
                                </Canvas>
                            </div>
                            <div className="tab-pane" id="tab-alt-3">
                                <PlanetPlots2D elements={elements} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h1 className="headline-alert">Search Result</h1>
                <form onSubmit={submitForm}>
                    <div className="form-group">
                        <input type="text" className="form-control" value={searchParams}
                            onChange={(e) => updateSearchParams(e.target.value)} />
                    </div>
                </form>
                <hr />
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-5">
                    {
                        elements.map(element => (
                            <div key={element.name} className="col mb-3">
                                <div className="card">
                                    <Image src={element.image + "&width=300&height=300"}
                                        className="card-img-top" alt={element.name} />
                                    <div className="card-body">
                                        <h5 className="card-title">{element.name}</h5>
                                        <p className="card-text">{element.description}</p>
                                        <a href="#" className="btn btn-primary">Buy</a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="pagination-container col-md-12">
                    <PaginationComponent
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={elementsCount}
                        paginate={paginateTo}
                    />
                </div>
                <hr />
                <div className="video-overlay"></div>
            </div>
        </Fragment>
    )
}
