import React, { Fragment, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import pdf from '../../assets/pdf/CosmicPlaces_whitepaper_0.1.pdf';
import MetaMaskConnection from '../metamask';
import './topbar.css';

export default function TopBar() {

    const uniswapLink = "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7234c954A93617D63E7F6E1c59A77887046C1c29";

    return (
        <Fragment>
            <div className='flex-row d-flex'>
                <div className='col-md-2'>
                    <Navbar.Brand className='mt-3' href="/">
                        <img src="assets/img/cosmic_places_logo.png" width="250px;" height="60px;" />
                    </Navbar.Brand>
                </div>
                <div className='col-md-7 d-flex align-items-center ml-5'>
                    <Nav className='nav-font' style={{fontSize: '100'}}>
                        <Nav.Link className='pt-2' href="/">Home</Nav.Link>
                        <Nav.Link className='pt-2' href="/about">About</Nav.Link>
                        <Nav.Link className='pt-2' href="/road-map">Roadmap</Nav.Link>
                        <Nav.Link className='pt-2' href={pdf} target="_blank" >Whitepaper</Nav.Link>
                        <Nav.Link className='pt-2' href="/contact">Contact</Nav.Link>
                        <Nav.Link className='pt-2' href="/marketplace">Marketplace</Nav.Link>
                        {/* <Nav.Link className='pt-2' href={uniswapLink} target="_blank">Buy PLVS</Nav.Link> */}
                    </Nav>
                </div>
                <div className='col-md-2 d-flex align-items-center mt-3 ml-0'>
                    <MetaMaskConnection />
                </div>
            </div>
        </Fragment>
    );
}