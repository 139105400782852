import { Fragment, useState } from "react";
import GoogleReCaptcha from "react-google-recaptcha";
import axios from 'axios';
import { sendTicket } from "../../components/services/service";

export default function ContactUs() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("Technical problem");
    const [message, setMessage] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [status, setStatus] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');

    const submitForm = (e) => {
        e.preventDefault();
        const mail = {
            name: name,
            email: email,
            message: `Subject: ${subject} Details: ${message}`,
            recaptchaToken
        }
        sendTicket(mail)
            .then(response => {
                setStatus('success');
                setStatusMessage('Email sent successfully');
            })
            .catch(error => {
                setStatus('error');
                setStatusMessage('Failed to send email');
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Fragment>
            <div className="container">
                <form onSubmit={submitForm}>
                    <div className="form-group">
                        <label htmlFor="name">Your name</label>
                        <input type="text" className="form-control" id="name" placeholder="Please enter your name ..."
                            value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        <input type="email" className="form-control" id="email" placeholder="Please enter your email adress ..."
                            value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="request">How can we help you?</label>
                        <select id="request" className="form-control selectpicker" data-style="btn-highlight"
                            onChange={(e) => setSubject(e.target.value)}>
                            <option>Technical problem</option>
                            <option>Business inquiry</option>
                            <option>Application</option>
                            <option>General Support</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Your message</label>
                        <textarea className="form-control" id="message" rows="5" placeholder="Please enter your message ..."
                            value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="agb" required />
                        <label className="form-check-label" htmlFor="agb"><i className="fa fa-check"></i> I agree to the terms of service</label>
                    </div>
                    <GoogleReCaptcha sitekey={process.env.REACT_APP_SITE_KEY} required onChange={(token) => setRecaptchaToken(token)} />
                    <div className="form-group mt-5">
                        <button className="btn btn-highlight" type="submit" onSubmit={submitForm}>Send Message</button>
                    </div>
                </form>
                <div className="container m-3 ml-0">
                    {status && <div className={status === 'success' ? 'success' : 'error'}>{statusMessage}</div>}
                </div>
            </div>
        </Fragment>
    )
}