import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Line, OrbitControls, PerspectiveCamera, Sphere, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame, useLoader } from "@react-three/fiber";
import { Bloom, EffectComposer, Noise, Selection, SelectiveBloom } from "@react-three/postprocessing";


export function OrbitalColored2() {
    const colorMap = useTexture('./assets/img/earth_texture.jpg');
    // const colorMap = useTexture('./img/sun.jpg');
    const colors = [0xffcc6f, 0xffd2a1, 0xfff4ea, 0xf8f7ff, 0xcad7ff, 0xaabfff]
    let refs = useRef([]);
    let sunref = useRef();
    refs.current = refs.current.slice(0, 2);

    const [elRefs, setElRefs] = useState([]);
    let arrLength = 7;

    useEffect(() => {
        setElRefs((elRefs) => Array(arrLength).fill().map((_, i) => elRefs[i] || createRef()));
    }, [arrLength]);

    useEffect(() => {
        reset();
    }, [
        elRefs, 
        elRefs[0]?.current?.rotation?.y, 
        elRefs[1]?.current?.rotation?.y,
        elRefs[2]?.current?.rotation?.y,
        elRefs[3]?.current?.rotation?.y,
        elRefs[4]?.current?.rotation?.y
    ]);

    useFrame((state, delta) => {
        for (let i = 0; i < elRefs.length; i++) {
            elRefs[i].current.rotation.y += delta * 0.07 * Math.log(1 / i + 2);
        }
    })

    function reset() {
        for (let i = 0; i < elRefs.length; i++) {
            elRefs[i].current.rotation.y = 10 * (i + 1);
        }
    }

    function Sun({ type }) {
        const colorTypes = [0xffcc6f, 0xffd2a1, 0xfff4ea, 0xf8f7ff, 0xcad7ff, 0xaabfff]

        return (
            <mesh ref={sunref}>
                <sphereGeometry args={[10, 50, 50]} />
                <meshStandardMaterial color={colorTypes[type]} />
            </mesh>
        );

    }

    function Planet({ radius, ref, type }) {
        return (
            <mesh ref={ref} position={[radius, 0, 0]} >
                <sphereGeometry args={[2, 50, 50]} />
                <meshBasicMaterial color={colors[type]} map={colorMap} />
            </mesh>
        )
    }

    function Planets({ elRefs }) {
        return (
            elRefs.map((elRef, i) => {
                return (
                    <mesh ref={elRef} key={i}>
                        <Planet radius={i * 10 + 10} type={i}/>
                    </mesh>
                )
            })
        )
    }

    function Orbits() {
        let orbits = Array.from({length: arrLength}, (v, k) => k); ;
        return (
            orbits.map((o, i) => {
                return (
                    <Orbit radius={i * 10 + 10} />
                )
            })
        )
    }

    function Orbit({ radius }) {
        let points = [];
        for (let i = 0; i <= 360; i++) {
            let theta = Math.PI / 180 * i;
            let x = (radius * 1) * Math.cos(theta);
            let y = radius * Math.sin(theta);
            points.push(new THREE.Vector3(y, 0, x));
        }
        return (
            <Line points={points} color="#FFF" lineWidth={0.5}>
                <meshBasicMaterial />
            </Line>
        )

    }
    return (
        <Fragment>
            <ambientLight intensity={1} />
            <OrbitControls
                enablePan={true} enableZoom={true} enableRotate={true}
            />
            <PerspectiveCamera
                position={[20, 0, 0]} fov={75}
                aspect={window.innerWidth / window.innerHeight}
                near={0.1} far={2000} makeDefault />
            <group position={[-80, 10, 0]} rotation={[0, 0, -Math.PI / 10]} >
                <Sun type={0} />
                <Planets elRefs={elRefs} />
                <Orbits />
            </group>
            <EffectComposer>
                <SelectiveBloom luminanceThreshold={0} luminanceSmoothing={0.9} height={200} 
                    selection={[sunref]} />
            </EffectComposer>
        </Fragment>
    )
}
