import axios from 'axios';

// http://localhost:5001/api/metadata/plots?planetNum=0

export function getElements() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + `/metadata`)
            .then(resolve).catch(reject);
    });
}

export function getPlots({planetNum}) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + `/metadata/plots?planetNum=${planetNum}}`)
            .then(resolve).catch(reject);
    });
}

export function sendTicket(mailData) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + `/support-ticket`, mailData)
            .then(resolve).catch(reject);
    });
}

export function searchElements(params) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + `/metadata`, {params: params})
            .then(resolve).catch(reject);
    });
}