import React, { Fragment, useRef, } from 'react';
import { Canvas, useFrame, } from '@react-three/fiber'
import { Html, OrbitControls, PerspectiveCamera, Plane } from '@react-three/drei';
import * as THREE from 'three';
import data from '../../assets/generated-galaxy.json';
import { useEffect } from 'react';
import { useState } from 'react';

export function Galaxy3JSOptimized({ elements }) {
    const texture = new THREE.TextureLoader().load('./assets/img/galaxy_texture3.png')
    const [stars, setStars] = useState([]);

    const labelStyle = {
        position: 'absolute',
        top: '-40px',
        left: '-50px',
        width: '100px',
        height: '20px',
        textAlign: 'center',
        fontSize: '14px',
        color: '#ffff',
        backgroundColor: '#0000',
        borderRadius: '5%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    useEffect(() => {
        let stars = [];
        elements.map((element, i) => {
            if (element.starNum === undefined) return;
            let starNum = elements[i].starNum;
            stars.push({
                pos: [data[starNum].pos.x, data[starNum].pos.y, data[starNum].pos.z],
                type: data[starNum].type,
                idx: starNum
            })
        });
        setStars(stars);
    }, [elements]);

    function RenderLabels({ stars }) {

        return (stars.map((star, i) =>
            <Fragment>
                <Html
                    key={`html_${i}`}
                    position={[star.pos[0] / 30, star.pos[1] / 30, star.pos[2] / 30]} color={0xffffff}>
                    <div key={`label_${i}`} style={labelStyle}>Star {star.idx}</div>
                </Html>
            </Fragment>
        ))
    }

    const ref = useRef();
    useFrame((state, delta) => {
        ref.current.rotation.z -= delta / 20;
    });

    function AnimatedPlane({ stars }) {
        return (
            <Plane
                ref={ref}
                args={[30, 30]}
                receiveShadow
                rotation={[0, Math.PI / 2, 0]}>
                <meshStandardMaterial
                    map={texture}
                    side={THREE.DoubleSide} />
                <RenderLabels stars={stars} />
            </Plane>
        )
    }

    return (
        <Fragment>
            <ambientLight intensity={1} />
            <OrbitControls
                enablePan={false} enableZoom={false} enableRotate={false}
            />
            <PerspectiveCamera
                position={[18, 0, 0]}
                fov={75}
                aspect={window.innerWidth / window.innerHeight}
                near={0.1}
                far={2000}
                makeDefault />
            <AnimatedPlane stars={stars} />
        </Fragment>
    );
}

