import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import contractJson from '../assets/contracts/SampleAsset.json';

// const web3 = createAlchemyWeb3("https://eth-sepolia.g.alchemy.com/v2/jZna3fZSwsA2aWKRR3Ps5bDHbQBSMb3P");
// const web3 = createAlchemyWeb3("http://127.0.0.1:8545");
const web3 = createAlchemyWeb3(`https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`);

const contractAddress = "0xAbE3560A407D0120D7Ae6815c80eE367643Dfe74";

export const contractObject = new web3.eth.Contract(
    contractJson.abi,
    contractAddress
);

async function requestAccounts(ethereum) {
    try {
        const accounts = await ethereum.request({
            method: 'eth_requestAccounts'
        })

        return accounts
    } catch (error) {
        console.error(error)

        return false
    }
}

function chainIsPolygon(ethereum) {
    return !!(ethereum && ethereum.chainId === '0x89')
}

function chainIsEthereum(ethereum) {
    return !!(ethereum && ethereum.chainId === '0x1')
}

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await requestAccounts(window.ethereum);
            const networkId = await chainIsEthereum(window.ethereum);
            if (addressArray) {
                if (networkId) {
                    return {
                        address: addressArray[0],
                        message: "Metamask connected.",
                        error: false
                    };
                } else {
                    return {
                        address: "",
                        message: "Wrong network",
                        error: true
                    };
                }
            } else {
                return {
                    address: "",
                    message: "No wallets found",
                    error: true
                };
            }
        } catch (err) {
            return {
                address: "",
                message: err.message,
                error: true
            };
        }
    } else {
        return {
            address: "",
            message: "Please install Metamask",
            error: true
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    message: "",
                    error: false
                };
            } else {
                return {
                    address: "",
                    message: "Click on connect.",
                    error: false
                };
            }
        } catch (err) {
            return {
                address: "",
                message: "Unable to get current wallet" + err.message,
                error: false
            };
        }
    } else {
        return {
            address: "",
            message: "Please install Metamask",
            error: true
        };
    }
};

export function addWalletListener(statusChanged) {
    if (window.ethereum) {
        window.ethereum.on('chainChanged', async () => {
            const networkId = await chainIsEthereum(window.ethereum);
            if (!networkId) {
                statusChanged({
                    address: "",
                    message: "Wrong network",
                    error: true
                });
            }
        })
        window.ethereum.on("accountsChanged", (accounts) => {
            statusChanged({
                address: accounts[0],
                message: "Connected",
                error: false
            });
        });
    } else {
        statusChanged({
            address: "",
            message: "Please install Metamask",
            error: true
        });
    }
}

