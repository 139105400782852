import React, { useEffect, useState } from 'react';

export function Image({ src, ...props }) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Reset isLoaded state when src prop changes
        setIsLoaded(false);
    }, [src]);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div>
            {!isLoaded && <div style={{ width: "300px" }}
            >Loading...</div>}
            <img
                src={src}
                onLoad={handleImageLoad}
                onError={() => console.log('Failed to load image')}
                style={{ display: isLoaded ? 'block' : 'none' }}
                {...props}
            />
        </div>
    );
}
