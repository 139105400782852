import { Fragment } from "react";
import './footer.css';

export default function Footer() {
    return (
        <Fragment>
            <div id="main-menu" className="collapse megamenu show">
                <div className="container"><footer>
                    <nav>
                        <ul>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a href="/terms">Terms of Service</a></li>
                        </ul>
                    </nav>
                    <div className="social-links">
                        <a href="https://www.facebook.com"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
                    </div>
                </footer>
                    <div className="copyright">
                        &copy; 2023 Cosmic Places. All rights reserved.
                    </div>
                </div>
            </div>
        </Fragment>
    )
}