import { Fragment } from "react";

export default function TermsOfUse() {
    return (
        <Fragment>
            <div className="container">
                <h1>Terms Of Use</h1>
                <p class="c16"><span class="c9"></span></p>
                <a id="t.eed6653bc9e9b78b7a7ca27c129796be972dc787"></a><a id="t.0"></a>
                <table class="c13">
                    <tr class="c14">
                        <td class="c11" colspan="1" rowspan="1">
                            <p class="c12"><span class="c1 c5">COSMICPLACES TERMS OF USE</span></p>
                            <p class="c10"><span class="c1 c0"></span></p>
                        </td>
                    </tr>
                </table>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">(Effective March 5</span><span class="c0 c18">th</span><span class="c1 c0">, 2023) </span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c17"><span class="c1 c5">Table of Contents</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_2-0 start" start="1">
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Introduction </span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">About Us</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">How to contact us </span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Use of the Service</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Eligibility</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">The Platform</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">User Login</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">CosmicPlaces tokens, rewards and Events</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Transaction and Other Fees</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Risk Assumption</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c5">Disclaimer</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c5">Limitations</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c5">Revisions</span><span class="c0">&nbsp;</span><span class="c5">and</span><span class="c0">&nbsp;</span><span class="c5">errata</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Regulatory and Legal</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">For European Union (EU) Users</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">United States Legal Compliance</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Site Terms of Use Modifications</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Governing Law</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Disputes</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Electronic Communications</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Copyright License</span></li>
                    <li class="c4 c7 li-bullet-0"><span class="c1 c5">Complaints</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0 start" start="1">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Introduction to the Terms</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">Welcome to </span><span class="c5">cosmicplaces.com</span><span class="c0">, owned and operated by </span><span class="c5">CosmicPlaces Technologies, </span><span class="c0">a company registered in the United Kingdom. This Terms and Conditions is a binding contract between you, individual or entity user (&ldquo;you&rdquo;) and </span><span class="c5">CosmicPlaces (&ldquo;https://cosmicplaces.com&rdquo;)</span><span class="c1 c0">&nbsp;(also herein referred to as &ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;) (together, the &ldquo;Parties&rdquo; and each a &ldquo;Party&rdquo;). </span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">These Terms Agreement govern your access to and use of the </span><span class="c5">CosmicPlaces</span><span class="c0">&nbsp;services provided on or in connection with our website; including without limitation using and exploring our various network and all related products offered by </span><span class="c5">CosmicPlaces</span><span class="c1 c0">, for users&rsquo; to view, participate and/or watch to earn reward on the CosmicPlaces site. The date on which you first log in your Account (as defined herein) is referred to as the&nbsp;&quot;Effective Date&quot;.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="2">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">About us</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c5">CosmicPlaces</span><span class="c1 c0">&nbsp;provides a platform where players can explore a universe of possibilities, buy and sell plots on different planets, and compete for resources in a dynamic and engaging virtual environment on the blockchain. If you have any questions please send us a message. We will be happy to help you.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="3">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">How to contact us</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">You can contact us by using the Contact us Section on the website or &nbsp;send us an email to </span><span class="c5">support@cosmicplaces.com</span><span class="c1 c0">&nbsp;</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="4">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Use of the Service</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">For purposes of these Terms, &ldquo;user&rdquo;, &ldquo;you&rdquo;, and &ldquo;your&rdquo; means you as the user of the Service. If you use the Service on behalf of a company or other entity then &ldquo;you&rdquo; includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity&#39;s behalf.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">BY CLICKING TO ACCEPT AND/OR USING OUR SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICE.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="5">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Eligibility</span></li>
                </ol>
                <p class="c2"><span class="c0 c1"></span></p>
                <p class="c4"><span class="c0">You may not create, access or participate in any of </span><span class="c5">CosmicPlaces </span><span class="c0">Services</span><span class="c5">, </span><span class="c0">if you are not eligible or lawfully permitted to do so. The </span><span class="c5">CosmicPlaces </span><span class="c1 c0">is open only to individuals who are 16 years and above and have the right and authority to enter into this Agreement, and are fully able and competent to satisfy the terms, conditions, and obligations herein.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="6">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">The Platform</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c5">CosmicPlaces</span><span class="c1 c0">&nbsp;operates a Metaverse design with a resource-rich territory where players can discover new elements, with unique properties that have the potential to revolutionize technology and the blockchain industry. With CosmicPlaces users are able to explore different planets and find deposits of these new element, which can be sold or create new products.</span></p>
                <p class="c2"><span class="c1 c5"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="7">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">User Login</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">To use this website, you may be asked to register for an account and provide private information such as email address, Password etc. You agree to be responsible for ensuring the accuracy of your information, and maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">We reserve all rights to terminate accounts, edit or remove content and cancel orders in our sole discretion.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="8">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">CosmicPlaces tokens, rewards and Events</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">CosmicPlaces provides&rsquo; exclusive access to resources, discounts, and special in-game events which are just some of the rewards players can earn by becoming part of the </span><span class="c5">CosmicPlaces</span><span class="c1 c0">&nbsp;community. All token rewards are based on chance. You agree that there can be no guarantee or assurance of the uniqueness, originality or quality of any CosmicPlaces Tokens or rewards available on the site. </span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c5">CosmicPlaces</span><span class="c0">&nbsp;makes no representations or warranties as to the quality, origin, or ownership of any content found on the site. </span><span class="c5">CosmicPlaces</span><span class="c0">&nbsp;shall not be liable for any errors, misrepresentations, or omissions in, of, and about, the content, nor for the availability of the content. </span><span class="c5">CosmicPlaces </span><span class="c1 c0">Company shall not be liable for any losses, injuries, or damages from the purchase, inability to purchase, display, or use of content.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="9">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Transaction and Other Fees</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">Most cryptocurrencies allow market participants to offer miners (i.e., parties that process transactions and record them on a blockchain or distributed ledger) the ability to earn a fee. While not mandatory, a fee is generally necessary to ensure that a transaction is promptly recorded on a blockchain or distributed ledger. The amounts of these fees are subject to market forces, and it is possible that the fees could increase substantially during a period of increased activity or volatility.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="10">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Risk Assumption</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">You accept and acknowledge: The value of an NFTs is subjective. Prices of NFTs are subject to volatility and fluctuations in the price of cryptocurrency can also materially and adversely affect NFT prices. You acknowledge that you fully understand this subjectivity and volatility and that you may lose money. You also agree that:</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_15-0 start" start="1">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact the development of those ecosystems and related applications, and could therefore also negatively impact the potential utility of NFTs.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">The regulatory regime governing blockchain technologies, non-fungible tokens, cryptocurrency, and other crypto-based items is uncertain, and new regulations or policies may materially adversely affect the development of the Service and the utility of NFTs.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">You are solely responsible for determining what, if any, taxes apply to your transactions. </span><span class="c1 c5">CosmicPlaces</span><span class="c1 c0">&nbsp;is not responsible for determining the taxes that apply to your NFTs.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">There are risks associated with purchasing items associated with content created by third parties through peer-to-peer transactions, including but not limited to, the risk of purchasing counterfeit items, mislabeled items, items that are vulnerable to metadata decay, items on smart contracts with bugs, and items that may become untransferable. You represent and warrant that you have done sufficient research before making any decisions to sell, obtain, transfer, or otherwise interact with any NFTs or accounts/collections.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">We do not control the public blockchains that you are interacting with and we do not control certain smart contracts and protocols that may be integral to your ability to complete transactions on these public blockchains. Additionally, blockchain transactions are irreversible and </span><span class="c1 c5">CosmicPlaces</span><span class="c1 c0">&nbsp;has no ability to reverse any transactions on the blockchain.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">There are risks associated with using Internet and blockchain based products, including, but not limited to, the risk associated with hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to your third-party Account. You accept and acknowledge that </span><span class="c1 c5">CosmicPlaces</span><span class="c1 c0">&nbsp;will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Service or any Blockchain network, however caused.</span></li>
                    <li class="c4 c6 li-bullet-0"><span class="c1 c0">The Service relies on third-party platforms and/or vendors. If we are unable to maintain a good relationship with such platform providers and/or vendors; if the terms and conditions or pricing of such platform providers and/or vendors change; if we violate or cannot comply with the terms and conditions of such platforms and/or vendors; or if any of such platforms and/or vendors loses market share or falls out of favor or is unavailable for a prolonged period of time, access to and use of the Service will suffer.</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="11">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Disclaimer</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">The materials on </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) web site are provided &ldquo;as is&rdquo;. </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. further, </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c1 c0">) does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</span></p>
                <p class="c2"><span class="c1 c5"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="12">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Limitations</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">In no event shall </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">)&rsquo;s Internet site, even if </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) or a </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c1 c0">) authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="13">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Revisions and Errata</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">The materials appearing on </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">)&rsquo;s web site could include technical, typographical, or photographic errors. </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) does not warrant that any of the materials on its web site are accurate, complete, or current. </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c0">) may make changes to the materials contained on its web site at any time without notice. </span><span class="c5">cosmicplaces.com</span><span class="c0">&nbsp;(</span><span class="c5">Owned by CosmicPlaces Technologies</span><span class="c1 c0">) does not, however, make any commitment to update the materials.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="14">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Regulatory and Legal</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">Legislative and regulatory changes or actions in the London Royal Courts of Justice or elsewhere may adversely affect the use, transfer, exchange, and value of </span><span class="c5">CosmicPlaces Tokens</span><span class="c0">. In addition, cryptocurrency markets and exchanges are not regulated with the same controls or customer protections available in equity, option, futures, or foreign exchange investing. There is no assurance that a person who accepts </span><span class="c5">CosmicPlaces Tokens</span><span class="c1 c0">&nbsp;as payment today will continue to do so in the future, and there may be few, if any, options regarding legal recourse.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="15">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">For European Union (EU) Users</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in including the European Union GDPR.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="16">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">United States Legal Compliance</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">You represent and warrant that (i) you are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) you are not listed on any United States government list of prohibited or restricted parties.</span></p>
                <p class="c2"><span class="c1 c5"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="17">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Site Terms of Use Modifications</span></li>
                </ol>
                <p class="c2"><span class="c1 c5"></span></p>
                <p class="c4" id="h.gjdgxs"><span class="c5">cosmicplaces.com</span><span class="c1 c0">&nbsp;may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms of Service of Use.</span></p>
                <p class="c2"><span class="c1 c5"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="18">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Governing Law</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">These terms and the relationship between you and </span><span class="c5">cosmicplaces.com</span><span class="c1 c0">&nbsp;is governed by the laws of England and Wales. </span></p>
                <p class="c4"><span class="c1 c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p class="c4"><span class="c1 c0">You irrevocably agree that the laws of England and Wales, shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims). The Customer agree that all claims or dispute shall be subjected to the exclusive jurisdiction of the Courts of England, and Wales.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="19">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Disputes </span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c0">Any Dispute between the Parties in connection with this Agreement must be resolved by arbitration. The relevant Parties shall then seek to agree and jointly appoint the Arbitrator.</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="20">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Electronic Communications</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">For the performance of the services, you (a) consent to receive communications from </span><span class="c5">us</span><span class="c0">&nbsp;in an electronic form via the Email address; and (b) agree that all terms and condition, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. You retain the right to opt-out of receiving Electronic Communication from us; however, opting out may prevent you from receiving messages regarding our services or special offers, by sending us a Opt-out message via our support email </span><span class="c5">support@cosmicplaces.com</span></p>
                <p class="c2"><span class="c1 c5"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="21">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Copyright License</span></li>
                </ol>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c0">Copyright(c) 2023 (s)</span><span>&nbsp;</span><span class="c5">CosmicPlaces</span><span class="c1 c0">&nbsp;website. Subject to the express provisions of these Terms of Service:</span></p>
                <ul class="c3 lst-kix_list_1-0 start">
                    <li class="c4 c6 li-bullet-0"><span class="c5">CosmicPlaces</span><span class="c0">, together with our Licensors</span><span class="c5">,</span><span class="c1 c0">&nbsp;own and control all the copyright and other intellectual property rights in the website and the material on our site are well reserved.</span></li>
                </ul>
                <p class="c2 c15"><span class="c1 c0"></span></p>
                <ol class="c3 lst-kix_list_3-0" start="22">
                    <li class="c4 c6 li-bullet-0"><span class="c1 c5">Complaints </span></li>
                </ol>
                <p class="c2"><span class="c1 c5"></span></p>
                <p class="c4"><span class="c0">If you have any questions or complaints regarding your use of the site or these Terms of Service, please send a customer support or enquiry to </span><span class="c5">support@cosmicplaces.com</span><span class="c1 c0">&nbsp;or by post</span></p>
                <p class="c2"><span class="c1 c0"></span></p>
                <p class="c4"><span class="c1 c5">CosmicPlaces</span></p>
                <p class="c4"><span class="c1 c5">United Kingdom</span></p>
            </div>

        </Fragment>
    )
}