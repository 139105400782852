import { Fragment } from "react";

export default function HomePage() {
    const sectionStyle = {
        "padding": "125px 0 200px"
    }

    const wrapperStyle = {
        // backgroundImage: "url(assets/img/galaxy_background1.jpg)",
        padding: "10px"
    }

    return (
        <Fragment>
            <div className="" style={wrapperStyle}>

                <section className="" style={sectionStyle}>
                    <div className="container d-flex">
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h1><b>Cosmic Places Metaverse</b></h1>
                                <br />
                                <h2>World's First Planets Metaverse & NFT Store</h2>
                                <p>Welcome to our metaverse platform, where you can explore a new galaxy of resource-rich planets and discover new opportunities.
                                    Our platform is designed for all ages, with features that will appeal to players of all levels of experience and interests.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <img src="assets/img/solar_system1.png" alt="Earth" />
                        </div>
                    </div>
                </section>
                <hr style={{ color: "white" }} />
                <section className="" style={sectionStyle}>
                    <div className="container d-flex">
                        <div className='col-md-6 d-flex align-items-center'>
                            <img src="assets/img/galaxy2_md_circle.png" alt="Earth" />
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2>Cosmic Places Metaverse</h2>
                                <p>Explore different planets and find deposits of the new element that has been discovered.
                                    Buy and sell plots on different planets, and design and customize your own unique spaces.
                                    Compete for resources with other players, and protect your interests from corporations with deep pockets and questionable ethics.
                                    Earn rewards and exclusive access to resources by becoming part of our community.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="" style={sectionStyle}>
                    <div className="container d-flex">
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2>Cosmic Places Metaverse</h2>
                                <p>Join our community today and explore a universe of possibilities!
                                    Sign up for a free account and start exploring the galaxy.
                                    Follow us on social media for updates and news about our platform.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <img src="assets/img/earthly_planet_md.png" alt="Earth" />
                        </div>
                    </div>
                </section>
            </div>
            <div className="video-overlay"></div>
        </Fragment>
    )
}