import React, { useEffect, useState } from 'react';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import { connectWallet, getCurrentWalletConnected, addWalletListener } from '../helpers/metatmask-utils';

export default function MetaMaskConnection() {
  
  const [status, setStatus] = useState({
    connected: false,
    account: '',
    errorMessage: null
  });

  useEffect(() => {
    async function fetchWallet() {
      const { address, error, message } = await getCurrentWalletConnected();
      if (error) {
        setStatus({ connected: false, account: null, errorMessage: message });
      } else {
        setStatus({ connected: true, account: address, errorMessage: null });
      }
    }
    fetchWallet();
    addWalletListener((walletStatus) => {
      if (walletStatus.error) {
        setStatus({ connected: false, account: null, errorMessage: walletStatus.message });
      } else {
        setStatus({ connected: true, account: walletStatus.address, errorMessage: null });
      }
    });
  }, [window.ethereum]);


  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    const { address, message, error } = await getCurrentWalletConnected();
    if (error) {
      setStatus({ connected: false, account: null, errorMessage: message });
    } else {
      setStatus({ connected: true, account: address, errorMessage: null });
    }
  };

  return (
    <div>
      <button className="btn btn-highlight" onClick={connectWalletPressed} >
        {
          status.account?.length > 0 ? (
            String(status.account).substring(0, 6) +
            "..." +
            String(status.account).substring(38)
          ) : (
            <div>Connect Wallet</div>
          )
        }
      </button>

    </div>
  );
}
