import { Fragment } from "react";

export default function PrivacyPolicy() {
    return (
        <Fragment>
            <div className="container">
                <p>Privacy Policy</p>
                <p class="c19 c21"><span class="c24">
                </span>
                </p>
                <a id="t.f77a35d01254a0707b4fd5b2e67232674d568b9b"></a><a id="t.0"></a>
                <table class="c22">
                    <tr class="c16">
                        <td class="c20" colspan="1" rowspan="1">
                            <p class="c18"><span class="c0">&nbsp;COSMICPLACES PRIVACY POLICY</span></p>
                            <p class="c18 c19"><span class="c0"></span></p>
                        </td>
                    </tr>
                </table>
                <p class="c10 c19"><span class="c1"></span></p>
                <p class="c17"><span class="c8">(Effective March 7</span><span class="c8 c23">TH</span><span class="c8">,</span><span class="c8 c23">,</span><span class="c1">&nbsp;2023)</span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c10"><span class="c0">Table of Contents</span></p>
                <ol class="c6 lst-kix_list_6-0 start" start="1">
                    <li class="c4 li-bullet-0"><span class="c0">Introduction</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">The CosmicPlaces website</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Controller</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">General Information</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Personal Data Collection</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Activity</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">How we use your Information and Legal Basis</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Third Parties we use</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Feedback</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Data Retention</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Children</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Data Security</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Your Choices </span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Notice To Certain International Members</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Commitment</span></li>
                    <li class="c4 li-bullet-0"><span class="c0">Business Transfers</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Changes to our Privacy Policy</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Contact Us</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c10"><span class="c0">PLEASE READ THIS PRIVACY STATEMENT CAREFULLY</span></p>
                <p class="c10 c19"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0 start" start="1">
                    <li class="c2 li-bullet-0"><span class="c0">Introduction</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">Welcome to </span><span class="c9">CosmicPlaces</span><span class="c8">&nbsp;site accessible at or </span><span class="c9">https://cosmicplaces.com</span><span class="c8">&nbsp;(the &quot;Site&quot;), hosted by Us. When this Policy mentions </span><span class="c9">CosmicPlaces</span><span class="c8">&nbsp;or </span><span class="c9">CosmicPlaces Technologies</span><span class="c1">, it refers to &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;, and we will be acting as your Data Controller.</span></p>
                <p class="c7"><span class="c1"><br />Our privacy promises:</span></p>
                <ul class="c6 lst-kix_list_21-0 start">
                    <li class="c2 li-bullet-0"><span class="c1">We value your privacy &amp; data security</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">We do not use your data for commercial purpose</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">We comply with the GDPR</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">We will never sell your data</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">You control your data.</span></li>
                </ul>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">After that, you&rsquo;re all set to use our Service. By accessing the website, you hereby consent to our Privacy Policy and agree to the terms described herein.</span><span>&nbsp;</span><span class="c1">If you are not comfortable with any aspect of this Privacy policy. You should immediately discontinue your access to the Site.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="2">
                    <li class="c2 li-bullet-0"><span class="c0">Controller</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">CosmicPlaces, a company duly incorporated under the laws of the United Kingdom, hereinafter &ldquo;CosmicPlaces&rdquo;, &ldquo;Service Provider&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;), is responsible for the collection of personal data via our site </span><span class="c9">https://cosmicplaces.com</span><span class="c1">/, (&ldquo;our site&rdquo;).</span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">When this Policy mentions CosmicPlaces it refers to your Data Controller. Our data privacy officer may be contacted at </span><span class="c9">support@cosmicplaces.com</span><span class="c1">/</span></p>
                <p class="c3 c27"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="3">
                    <li class="c2 li-bullet-0"><span class="c0">General Information</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">The terms &ldquo;We&rdquo;, &ldquo;Us&rdquo;,&rdquo; Our&rdquo; mean CosmicPlaces. The terms &ldquo;You&rdquo; and &ldquo;Your&rdquo; refer to You, as a user, client or visitor of our website provided Services. The term &ldquo;personal data&rdquo; refers to all information relating to an identified or identifiable natural person (hereinafter referred to as &ldquo;data subject&rdquo;); an identifiable natural person is one who can be identified directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or one or more specific characteristics expressing the physical, physiological, genetic, psychological, economic, cultural or social identity of that natural person, as set out in the GDPR. In addition, personal data comprises data of legal persons.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="4">
                    <li class="c2 li-bullet-0"><span class="c0">The CosmicPlaces website</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">When you access, and use the CosmicPlaces website, CosmicPlaces may automatically collect the following information. We</span><span class="c9">&nbsp;</span><span class="c1">collects and processes various information from Users, in many cases, these information constitute Users&#39; personal data. This information will be considered &quot;personal data&quot; when it can directly identify or allow us to identify a natural person. The categories of personal information we collect depend on how a User interact with our Service and the requirements of applicable law. </span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">To establish an account for you, we will request that you provide us with important information about yourself. If you choose not to share certain information with us, we may not be able to serve you effectively or offer you our Service. Any information you provide to us that is not required is voluntary;</span></p>
                <p class="c7"><span class="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <ol class="c6 lst-kix_list_43-0 start" start="1">
                    <li class="c2 li-bullet-0"><span class="c9">Your Usage Data</span><span class="c1">. We may automatically collect certain details of your access to and use of the site, including traffic data, logs and other communication data and the resources that you access and use on or through the site.</span></li>
                    <li class="c2 li-bullet-0"><span class="c9">Device Data</span><span class="c1">. We may collect data about your computer device and internet connection, including the IP address, operating system, browser type, network data, etc.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Communication</span><span class="c1">: we use your data to send you offers and other communications such as product updates, notices, support, &nbsp;and administrative communication that may be of interest to you, &nbsp;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Personalisation</span><span class="c1">: We use your personal data to develop and conduct our services. Some examples of how we use personal data include: improving our products and services; personalizing our communication with you; conducting market research; conducting customer surveys; engaging in more targeted, -based advertising;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Updates, Promotions</span><span class="c1">:</span><span class="c15">&nbsp;</span><span class="c1">Based on your consent, your personal data also may be used to keep you informed about new products, product upgrades, patches, special offers, and other products and services of </span><span class="c0">CosmicPlaces</span><span class="c1">&nbsp;and selected third parties if you have consented to receive such communications;</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="5">
                    <li class="c2 li-bullet-0"><span class="c0">Personal Data Collection</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">When you visit our website, and decide to use our service.</span><span class="c9">&nbsp;</span><span class="c8">You will be required to create an account with us. You agree to provide only true and correct data.</span><span class="c9">&nbsp;</span><span class="c1">When you use and interact with our services. </span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">We may collect, and use some personal identifiable data (&quot;Personal Data&quot;) such as;</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_42-0 start" start="1">
                    <li class="c7 c14 li-bullet-0"><span class="c0">Identity Data</span><span class="c1">&nbsp;includes first name, last name, username or similar identifier, job title. In some cases we might request you to provide your identity documents.</span></li>
                    <li class="c7 c14 li-bullet-0" id="h.gjdgxs"><span class="c0">Contact Data</span><span class="c1">&nbsp;includes billing information, email address.</span></li>
                    <li class="c7 c14 li-bullet-0"><span class="c0">Transaction Data</span><span class="c1">&nbsp;includes details about transactions, payments and other details of products and services you have purchased from us.</span></li>
                    <li class="c7 c14 li-bullet-0"><span class="c0">Technical Data</span><span class="c1">&nbsp;includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access the application and website.</span></li>
                    <li class="c7 c14 li-bullet-0"><span class="c0">Profile Data</span><span class="c1">&nbsp;includes your username and password, purchases or orders made by you, your preferences, feedback and survey responses.</span></li>
                    <li class="c7 c14 li-bullet-0"><span class="c0">Usage Data</span><span class="c1">&nbsp;includes data about how you use our website, products and services.</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">These personal data is neccessay to provide a better experience of our products and services.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="6">
                    <li class="c2 li-bullet-0"><span class="c0">Activity</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">When You use the Services, We receive and store certain information which may include Your Personal Information, regarding Your use of the Services. Examples include IP addresses, browser types, domain names, and other statistical data regarding Your use of the Services. We may use this data in a way that does not disclose any of Your personally identifiable information, including, but not limited to, for purposes of developing new product and service offerings</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="7">
                    <li class="c2 li-bullet-0"><span class="c0">How we use your Information and Legal Basis</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">Any processing of data requires a legal basis. If the processing is necessary for the performance of a contract to which the data subject is a party, or for the implementation of pre-contractual measures taken at the request of the data subject, the processing may be based on Art. 6, para. 1, b) of the GDPR. </span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">If the processing is necessary to safeguard the legitimate interests of the data controller or a third party, provided that the interests or fundamental rights and freedoms of the data subject, which require the protection of personal data, do not prevail, it can be based on Art. 6, para. 1, d) of the GDPR. We process and use the information we collect in various ways, in addition, We may also collect, hold and use your personal information for:</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_35-0 start" start="1">
                    <li class="c2 li-bullet-0"><span class="c0">Delivering our service</span><span class="c1">. When you create your account or use our Service, you accept our Terms of Service and thus enter into a contract. In order for us to fulfill our obligations under that agreement, we need to access and process your personal information. We will not be able to if you do not include your details in your account registration or if, when you initiate a transaction.</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">&zwj;</span><span class="c0">Managing transaction</span><span class="c1">: we Use your information to fulfil and complete user transation on the website;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Communication</span><span class="c1">: we use your information to send you offers and other communications such as product updates, notices, support, &nbsp;and administrative communication that may be of interest to you, &nbsp;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Data Security and fraud prevention</span><span class="c1">: we use your data to protect our website user data and our server from malicious or dangerous activity;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Investigation</span><span class="c1">: Prevent and investigate unauthorised access to the Services, including other illegal activities;</span></li>
                    <li class="c2 li-bullet-0"><span class="c1">Comply with specific legal obligations, and enforcing our agreements.</span></li>
                </ol>
                <p class="c3"><span class="c0"></span></p>
                <p class="c7"><span class="c1">We also process data that cannot be assigned to any person. These are data that do not personally identify you, including anonymous information and aggregated data. This information helps us better understand how our visitors use the Services, analyze demographics, interests and behaviors of our visitors, improve the Services, provide customized services and information to visitors, and similar purposes. You hold the right to opt out of receiving any of these communications as described under &quot;Your Choices&quot; section below.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="8">
                    <li class="c2 li-bullet-0"><span class="c0">Third Parties we use</span></li>
                </ol>
                <p class="c3"><span class="c0"></span></p>
                <p class="c7"><span class="c9">CosmicPlaces</span><span class="c8">&nbsp;uses services of third parties, such as e-mail service providers and hosting providers that act as independent contractors on behalf of </span><span class="c9">CosmicPlaces</span><span class="c1">. We will not share your data with any third parties for the purposes of direct marketing.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">We have contracts in-place with our data processors. This means that they cannot do anything with your personal data unless we have instructed them to do it. They will not share your personal data with any organization apart from us. They will hold it securely and retain it for the period we instruct.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="9">
                    <li class="c2 li-bullet-0"><span class="c0">Feedback</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">If You contact Us to provide feedback, register a complaint, or ask a question, We will record any Personal Information and other content that You provide in Your communication so that We can effectively respond to Your communication. We reserve the right to use this information in any manner permitted by law, to respond to Your communication. The processing of your personal data for feedback processing is based on Art. 6, para. 1, d) or f) of the GDPR.</span></p>
                <p class="c7"><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <ol class="c6 lst-kix_list_22-0" start="10">
                    <li class="c2 li-bullet-0"><span class="c0">Data Retention </span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">For the sake of the GDPR, we are entitled to keep your Personal data only for as long as we need it for legitimate business purposes and as required by applicable law. We will take reasonable steps to erase any Data that we hold from you, where:</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_30-0 start" start="1">
                    <li class="c7 c14 li-bullet-0"><span class="c1">we no longer need the Data for the purposes for which it was collected and we are not otherwise entitled to retain such data; and</span></li>
                    <li class="c7 c14 li-bullet-0"><span class="c1">we are not required or obligated by law, regulation or court order to retain the data.</span></li>
                </ol>
                <p class="c7"><span class="c1">If certain data has been provided to third parties in accordance with this Privacy policy, retention of that data will be subject to the policies of the relevant third party.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="11">
                    <li class="c2 li-bullet-0"><span class="c0">Children</span></li>
                </ol>
                <p class="c7"><span class="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p class="c7"><span class="c1">We comply with the Children Privacy Protection policy. Hence, our services is not directed to children under the age of 16. For this reason, we do not provide nor knowingly collect personal data from children under the age of 16. If you believe that a child under the age of 16 have used our service, please contact us and we will delete that data immediately.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="12">
                    <li class="c2 li-bullet-0"><span class="c0">Data Security</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">We have put in place numerous technical, organizational and physical safeguards measures designed to protect the personal data of users. However, no online security measures are guaranteed. You agree that your use of The site is at your own risk</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="13">
                    <li class="c4 li-bullet-0"><span class="c0">Your Choices</span></li>
                </ol>
                <p class="c11"><span class="c1">Under the General Data Protection Regulation, you are entitle to the choices below;</span></p>
                <ul class="c6 lst-kix_list_19-0 start">
                    <li class="c11 c26 li-bullet-0"><span class="c0">Exercise of Rights</span><span class="c1">: You can exercise your rights as defined in GDPR, please send a request via e-mail</span><span class="c0">&nbsp;support@cosmicplaces.com</span><span class="c1">, to exercise your rights.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Revocation of Consent</span><span class="c1">: You hold right to revoke your consent for processing of your data in the future at any time. However, this does not affect the lawfulness of Data processing based on the consent before the revocation.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right of Access</span><span class="c1">: You have the right to obtain (i) confirmation as to whether or not your Data is being processed by us and, if so, (ii) request for more specific data on the Data.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Rectification</span><span class="c1">: You have the right to obtain from us the rectification of inaccurate Data concerning you. In case the Data processed by us is not correct, we will rectify these without undue delay and inform you of this rectification.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Erasure</span><span class="c1">: Should you decide that you do not want us to process your data any further, please send a request via e-mail to </span><span class="c0">support@cosmicplaces.com</span><span class="c1">. We will erase your Data immediately and inform you of this process. Should mandatory provisions of law prevent such erasure, we will inform you without undue delay thereof.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Restriction of Processing</span><span class="c1">: You have the right to obtain from us a restriction of processing of your Data;</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Data Portability</span><span class="c1">: You have the right to (i) receive your Data in a structured, commonly used and machine-readable format and (ii) transmit those Data to another controller without hindrance from us.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Object</span><span class="c1">: You have the right to object at any time to the processing of Data.</span></li>
                    <li class="c2 li-bullet-0"><span class="c0">Right to Lodge a Complaint</span><span class="c1">: You have the right to lodge a complaint with a supervisory authority, if you think that the processing of Data infringes applicable law, especially the GDPR&quot;)</span></li>
                </ul>
                <p class="c11"><span class="c1">Learn more about these rights, and find out how you can exercise your rights by sending us a message.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="14">
                    <li class="c2 li-bullet-0"><span class="c0">Notice To International Members</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">If you are a citizen of any European union or the United Kingdom and have questions about the accuracy of data that we have collected about you, you can have access to that data in order to verify and update it, unless we are permitted or required under applicable laws to refuse your access to such data. You may write or email us for such request.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="15">
                    <li class="c2 li-bullet-0"><span class="c0">Commitment</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">We take the responsibility to protect the security and privacy of our users seriously. We have established effective policies that define our commitment to data security and the information technology we manage. We are continually reviewing policies and procedures to assure that they are appropriate and effective in meeting our commitments to our community, our users, customers and ourselves. Any suspicious attempt to breach our terms and policies, or to engage in any type of unauthorized action involving our information systems, is regarded as potential criminal activity. All suspicious computer mischief will be reported to the appropriate authorities.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="16">
                    <li class="c2 li-bullet-0"><span class="c0">Business Transfers</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">In connection with, or during negotiations of, sale of company assets, any merger, financing, or acquisition of all or a portion of our business. We may share or transfer your data (including your personal data) for business transfer purposes. You will be notified via email and/or a prominent notice on the website of any change in ownership or related uses or disclosures of your data, as well as any choices you may have regarding your data in such circumstances.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="17">
                    <li class="c2 li-bullet-0"><span class="c0">Changes To our Privacy Policy</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c1">Changes to this privacy policy may be made by us from time to time. In no event will these changes result in the degradation of any security measures designed to protect you from unauthorized disclosure.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <ol class="c6 lst-kix_list_22-0" start="18">
                    <li class="c2 li-bullet-0"><span class="c0">Contact Us</span></li>
                </ol>
                <p class="c3"><span class="c1"></span></p>
                <p class="c7"><span class="c8">We hope that we can satisfy any queries you may have about the way in which we process your personal information, so we encourage you to submit your claim to our Data Protection Officer at; </span><span class="c0">support@cosmicplaces.com</span></p>
                <p class="c3"><span class="c0"></span></p>
                <p class="c7"><span class="c1">However, if this does not satisfy you, you also have the right to complain to the data protection authority in the location in which you live, work or believe a data protection breach has occurred.</span></p>
                <p class="c3"><span class="c1"></span></p>
                <div>
                    <p class="c12"><span class="c25"></span></p>
                    <p class="c18 c19"><span class="c5"></span></p>
                </div>
            </div>

        </Fragment>
    )
}