import { Fragment } from "react";

export default function AboutUs() {
    return (
        <Fragment>
            <div className="container">
                <div className="col-12">
                    <h1>About</h1>
                </div>
                <div className="col-12 mt-3">
                    <h2>Explore a Universe of Possibilities</h2>
                </div>
                <div className="col-12 mt-3">
                    <h3>Introducing the Metaverse of Resource-Rich Planets</h3>
                </div>
                <div className="col-12 mt-3">
                    <p>In the far reaches of the universe, a new galaxy has been discovered, full of planets with unique ecosystems and abundant resources. Scientists and explorers from across the universe are flocking to this new frontier in search of new discoveries and opportunities.</p>
                    <p>However, the race to stake claim on these planets is not without its challenges. Corporations with immense resources and influence are vying for control of the most valuable planets, while settlers hope to strike it rich by finding their own deposits of the new element that has been discovered.</p>
                    <p>This is where our metaverse comes in. We are creating a platform where players can explore this universe of possibilities, buy and sell plots on different planets, and compete for resources in a dynamic and engaging virtual environment.</p>
                    <p>Our metaverse is set in a resource-rich territory where a new element has been discovered, with unique properties that have the potential to revolutionize technology and industry. Players will be able to explore different planets and find deposits of this new element, which they can then sell or use to create new products.</p>
                    <p>However, the competition for resources is fierce. Players will need to band together to protect their interests, or they risk losing everything to the corporations with deep pockets and questionable ethics.</p>
                    <p>But our metaverse is not just about competition and conflict. It's also about exploration, discovery, and creativity. Players can design and customize their plots on different planets, creating unique and personalized spaces that reflect their personalities and interests.</p>
                    <p>We also offer incentives to encourage players to buy plots on different planets. Exclusive access to resources, discounts, and special in-game events are just some of the rewards players can earn by becoming part of our community.</p>
                    <p>Our metaverse is designed for all ages, with features that will appeal to players of all levels of experience and interests. Whether you're a seasoned gamer or new to virtual worlds, our platform offers a fun and engaging way to explore this universe of possibilities.</p>
                    <p>In conclusion, the metaverse we are creating is an exciting new frontier for exploration, creativity, and competition. With a rich backstory and dynamic gameplay, players will have endless opportunities to discover new things, connect with others, and create their own unique experiences. Join us today and explore a universe of possibilities!</p>
                </div>
            </div>

        </Fragment>
    )
}